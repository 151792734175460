body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    color: var(--ion-color-dark);
}

h1 {
    font-size: 28px;
    @media (min-width: 993px) {
        font-size: 40px;
    }
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 22px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}
