.article-list {
    display: flex;
    flex-flow: column nowrap;

    &--vertical {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        overflow-x: scroll;
        overflow-y: visible;
        width: 100%;
        padding: 10px 0;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-padding: 0 16px;
        @media (min-width: 992px) {
            width: auto;
            &.abs {
                position: absolute;
            }
        }
    }

    .article-wrap:first-child {
        &.story {
            margin-top: 0;
        }
    }
}

.article-wrap {
    &:last-child {
        margin-bottom: 0;
    }

    &.story {
        margin: 48px 0 0;

        .story-of {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .avatar {
                flex-shrink: 0;
                overflow: hidden;
                margin-right: 8px;
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
                border-radius: 50%;
                width: 40px;
                height: 40px;
            }

            h3 {
                padding: 0;
            }
        }
    }
}

.article {
    position: relative;
    display: flex;
    overflow: hidden;
    margin-bottom: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    height: 48vh;
    cursor: pointer;
    transition: transform 0.3s ease 0.1s;

    @media (min-width: 993px) {
        height: 44vh;
    }

    &--vertical {
        flex-shrink: 0;
        margin: 0 16px 0 0;
        width: 85%;
        scroll-snap-align: center;

        @media (min-width: 992px) {
            width: 35%;
        }

        &:first-child {
            margin-left: 16px;

            @media (min-width: 992px) {
                margin-left: 0;
            }
        }

        &:last-child {
            @media (min-width: 992px) {
                margin-right: 16px;
            }
        }
    }

    &:hover {
        img {
            @media (min-width: 1280px) {
                transform: scale(1.1);
            }
        }
    }

    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        width: inherit;
        height: inherit;
        padding-left: 100%;
        background: linear-gradient(
                179.83deg,
                rgba(0, 0, 0, 0) 50%,
                rgba(0, 0, 0, 0.6) 99.85%
            ),
            linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
    }

    img {
        width: 100%;
        height: 100%;
        transition: transform 1s ease 0.1s;
        object-fit: cover;
    }

    .readtime {
        position: absolute;
        top: 16px;
        left: 16px;
        display: flex;
        align-items: center;
        border-radius: 12px;
        padding: 4px 16px 4px 14px;
        background: rgba(0, 0, 0, 0.5);
        font-size: 10px;
        font-weight: 600;
        color: var(--ion-color-primary-contrast);

        &::before {
            content: "";
            display: inline-block;
            margin-right: 4px;
            width: 16px;
            height: 16px;
            background: url("/assets/images/icons/clock.svg");
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    h4 {
        position: absolute;
        z-index: 2;
        bottom: 16px;
        left: 16px;
        right: 16px;
        color: var(--ion-color-primary-contrast);
    }
}
