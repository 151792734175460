.component {
    font-size: 16px;
    line-height: 24px;

    &--title_lg {
        margin-bottom: 8px;

        h2 {
            margin: 0;
            font-size: 22px;
            font-weight: 500;

            line-height: 26px;
        }
    }

    &--title_sm {
        margin-bottom: 8px;

        h3 {
            margin: 0;
            font-size: 18px;
            font-weight: 500;

            line-height: 26px;
        }
    }

    &--textarea {
        margin-bottom: 32px;

        p,
        ul,
        ol,
        table {
            margin: 16px 0 0;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            color: var(--ion-color-dark-tint);

            &:first-child {
                margin-top: 0;
            }
        }

        ul,
        ol {
            margin-left: 20px;
            padding-left: 20px;
        }

        strong {
            font-weight: 600;
            color: var(--ion-color-dark);
        }

        table {
            display: block;
            overflow-x: auto;
            width: 100%;
            border-collapse: collapse;

            td,
            th {
                border: 1px solid var(--ion-color-light-shade);
                min-width: 260px;
                padding: 4px 8px;
                font-size: 14px;

                @media (min-width: 992px) {
                    min-width: 200px;
                }
            }
        }
    }

    &--image {
        margin-bottom: 32px;
    }

    &--poll {
        margin-bottom: 24px;
    }
}

.linked-profile {
    h2 {
        margin: 0 0 8px;
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
    }

    p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        color: var(--ion-color-dark-tint);
    }

    .profile-card {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0 0 48px 0;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 24px 16px;
        background: var(--ion-color-primary-contrast);

        img {
            margin-right: 16px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
            border-radius: 32px;
            width: 64px;
            height: 64px;
            object-fit: cover;
        }

        .column.text {
            display: flex;
            flex-direction: column;

            .name {
                margin: 0 0 6px 0;
                font-size: 18px;
                font-weight: bold;
                line-height: 100%;
                color: var(--ion-color-dark);
            }

            .label {
                font-size: 14px;
                font-weight: bold;
                line-height: 100%;
                color: var(--ion-color-primary);
            }
        }
    }
}

.news-pdf-attachment {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 48px 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 24px 16px;
    background: var(--ion-color-primary-contrast);
    cursor: pointer;

    .text {
        margin-top: -2px;
        margin-left: 10px;
        font-size: 16px;
        font-weight: 600;
        font-style: normal;
        line-height: 100%;
        color: var(--ion-color-dark);
    }
}
