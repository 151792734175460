/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
/* Typography */
@import url("https://fonts.googleapis.com/css?family=Raleway:800|Roboto:400,500,700&display=swap");
@import "theme/toasts.scss";
@import "theme/typography.scss";
@import "theme/layout.scss";
@import "theme/components.scss";
@import "theme/articles.scss";
@import "theme/icons.scss";
@import "theme/fonts.scss";
@import "theme/responsive";

$font-family-base: "Oxygen", sans-serif !default;

// Global styling
body {
    background: var(--ion-color-light-tint);
}

[hidden] {
    display: none !important;
}

.green {
    color: var(--ion-color-secondary);
}

.wrapper-div {
    @media (min-width: 992px) {
        margin: 0 auto;
        max-width: 768px;
        padding: 0px 40px;
    }
}

.ion-padding-horizontal,
[padding-horizontal] {
    @media (min-width: 992px) {
        padding-inline-start: 280px;
        -webkit-padding-start: 280px;
    }
}

ion-app {
    @media (max-width: 992px) {
        margin: 0 auto;
        box-shadow: 0 0 48px 0 rgba(0, 0, 0, 0.05);
    }
}

.searchbar-input {
    box-shadow: none !important;
    border-radius: 8px !important;

    @media (min-width: 993px) {
        box-shadow: none !important;
        border: 0 !important;
    }
}

ion-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    background: var(--ion-color-primary-contrast);

    @media (min-width: 992px) {
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
        padding-top: 8px;
        padding-bottom: 8px;
        -webkit-padding-start: 280px;
    }

    ion-toolbar {
        position: relative;

        .avatar-main {
            @media (min-width: 992px) {
                position: absolute;
                top: 4px;
                right: 6px;
                display: flex;
                align-items: center;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
                border-radius: 4px;
                padding: 8px 16px 8px;
                background: var(--ion-color-primary-contrast);
                -webkit-box-align: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .username {
            display: none;
            @media (min-width: 992px) {
                display: block;
                opacity: 0.5;
                margin-left: 8px;
                font-size: 16px;
                font-weight: 500;
                color: var(--ion-color-dark);
            }
        }

        .avatar {
            position: absolute;
            z-index: 9;
            left: 0;
            overflow: hidden;
            border-radius: 50%;
            width: 30px;
            height: 30px;

            @media (min-width: 992px) {
                position: relative;
            }

            @media (max-width: 991px) {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .search-icon {
            position: absolute;
            top: calc(50% - 12px);
            right: 0;
            width: 24px;
            height: 24px;
            @media (min-width: 992px) {
                left: 16px;
                right: inherit;
            }
        }
    }

    ion-title {
        font-size: 17px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        letter-spacing: 0;
        color: var(--ion-color-dark);
        @media (min-width: 993px) {
            display: none;
        }
    }

    ion-back-button.white {
        color: var(--ion-color-primary-contrast) !important;
    }

    ion-back-button.black {
        color: var(--ion-color-dark) !important;
    }
}

ion-content {
    @media (min-width: 992px) {
        --padding-start: 280px;
    }
}

app-navbar {
    .fixed-bottom {
        z-index: 1000;
        box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
        width: 100%;
        background: var(--ion-color-primary-contrast);
    }
}

.picker-opt-selected,
.picker-button.sc-ion-picker-md {
    color: var(--ion-color-primary) !important;
}

.list-md {
    padding-top: 0;
}

.searched-text {
    border-radius: 2px;
    padding: 2px 0;
    background: var(--ion-color-primary);

    &--current {
        background: var(--ion-color-warning);
    }
}

app-news,
app-news-detail,
app-manual,
app-contact-departments,
app-news,
app-event,
app-contacts,
app-menu-slider {
    margin-bottom: 27px !important;

    &.no-margin {
        margin-bottom: 0 !important;
    }

    @media (min-width: 992px) {
        margin-bottom: 0 !important;
    }
}

[data-redactor-type="articlelink"],
[data-article-chapter-link] {
    cursor: pointer;
    font-weight: bold;
    line-height: 100%;
    color: var(--ion-color-primary);
}

.modal {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .modal-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.4;
        background: var(--ion-color-dark);
    }

    .modal-content {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 15px;
        width: 100%;

        max-width: 90%;
        padding: 32px;
        background: var(--ion-color-primary-contrast);
    }
}

@media (max-width: 680px) {
    .wysiwyg {
        figure {
            margin: 1em 20px !important;
        }
    }
}

ion-modal.datepicker-modal {
    --height: fit-content;
    --width: fit-content;
    --border-radius: 8px;
}

ion-modal.document-navigation-modal {
    --height: auto;
    --width: 100%;
    --border-radius: 20px 20px 0px 0px;

    @media (min-width: 1280px) {
        --width: vw(550px);
        --border-radius: 20px;
    }

    .ion-page {
        position: relative;
        display: block;
        contain: content;
    }
}

ion-modal.attend-modal {
    --height: auto;
    --width: 100%;
    --border-radius: 20px 20px 0px 0px;

    @media (min-width: 1280px) {
        --width: vw(550px);
        --border-radius: 20px;
    }

    .ion-page {
        position: relative;
        display: block;
        contain: content;
    }
}

ion-modal.accode-document-modal {
    --height: auto;
    --width: 100%;
    --border-radius: 20px 20px 0px 0px;

    @media (min-width: 1280px) {
        --width: vw(550px);
        --border-radius: 20px;
    }

    .ion-page {
        position: relative;
        display: block;
        contain: content;
    }
}
