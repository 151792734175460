@use "sass:math";

$tablet-landscape: "max-width: 1279px";
$tablet-portrait: "max-width: 992px";
$phone: "max-width: 576px";

:root {
    --factor: 1920;

    @media ($tablet-portrait) {
        --factor: 992;
    }

    @media ($phone) {
        --factor: 375;
    }
}

@function vw($px: 0px) {
    $num: math.div($px, $px * 0 + 1); // cast to number
    @return calc(#{$num} / var(--factor) * 100vw);
}
