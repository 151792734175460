:root {
    --ion-color-primary: #0069b4;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-shade: #0b578e;
    --ion-color-primary-tint: #4d88b3;

    --ion-color-secondary: #96b522;

    --ion-color-tertiary: #cbdeeb;
    --ion-color-tertiary-tint: #e6eff4;

    --ion-color-warning: #f1d62d;

    --ion-color-danger: #a32929;

    --ion-color-dark: #0d0d0d;
    --ion-color-dark-tint: #4d4f51;

    --ion-color-medium: #cccccc;
    --ion-color-medium-shade: #b5b5b5;

    --ion-color-light: #e6e7e7;
    --ion-color-light-contrast: #333333;
    --ion-color-light-tint: #fcfdfd;
    --ion-color-light-shade: #dddddd;
}
