ion-toast {
    --ion-color-base: var(--ion-color-primary);
    --ion-color-success: var(--ion-color-primary);
    --ion-color-danger: var(--ion-color-primary);
    --border-radius: 8px;
}

ion-toast::part(container) {
    border-radius: 8px;
    background: var(--ion-color-primary)
        url(../assets/icon/green-circle-white-outline.svg) no-repeat bottom
        right !important;
}

ion-toast::part(message) {
    font-family: "Segoe UI Emoji", "Raleway", serif;
    display: flex;
    padding: 14px 16px 32px 4px !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
