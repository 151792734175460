ion-icon {
    &[class*="icon-"] {
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
    }

    &[class*="icon-search"] {
        mask-image: url(../assets/images/icons/search.svg);
    }

    &[class*="icon-more"] {
        mask-image: url(../assets/images/icons/more.svg);
    }

    &[class*="icon-zoom-in-out"] {
        mask-image: url(../assets/images/icons/zoom-in-out.svg);
    }
}
